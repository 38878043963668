(function () {
  'use strict';

  angular
    .module('login.reset')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('login.reset', {
        url: '/reset/:token',
        templateUrl: 'login/reset/reset.tpl.html',
        controller: 'ResetCtrl',
        controllerAs: 'vm',
        authentication: false
      });
  }
}());
